import { useEffect, useRef } from 'react';
import { updatingFrameHeightOnLoadOrResize } from '@utils/iframe';
import { ModalWindow } from '../../UI/ModalWindow/ModalWindow';
import styles from './TermsModal.module.scss';

interface TermsModalProps {
  text: string;
  open: boolean;
  onClose: () => void;
}

export const TermsModal = ({ text, open, onClose }: TermsModalProps) => {
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => updatingFrameHeightOnLoadOrResize(iFrameRef.current), []);

  return (
    <ModalWindow open={open} onClose={onClose} classNameInner={styles.modal_content}>
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </ModalWindow>
  );
};
