/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { logger } from '@logger';
import { SEE_ALL, VIEW_ALL } from '@constants/header';

export const useViewAllTracking = (label?: string | null) => {
  const hasSeeOrViewAll = [SEE_ALL, VIEW_ALL].some((p: string) => label?.toLowerCase().includes(p));

  const trackViewAll = useCallback(() => {
    if (!label) {
      logger.warn('Navigation label was not provided for tracking!');
      return;
    }

    if (hasSeeOrViewAll) {
      sendGtmEvent(
        GtmCustomEvent({
          event: 'view_all',
          event_name: 'view_all_click',
          ua_category: 'view_all',
          ua_action: 'click',
          ua_label: label,
        }),
      );
    }
  }, [hasSeeOrViewAll, label]);

  const onClick = useCallback(() => {
    trackViewAll();
  }, [trackViewAll]);

  const onKeyDown = useCallback(
    ({ key }) => {
      if (key === 'Enter') trackViewAll();
    },
    [trackViewAll],
  );

  return {
    onClick,
    onKeyDown,
  };
};
