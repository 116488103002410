import { onEvent } from '@modules/event';

const getHeightOfBody = (window: Window): number => {
  const {
    marginTop,
    borderTopWidth,
    paddingTop,
    height,
    paddingBottom,
    borderBottomWidth,
    marginBottom,
  } = window.getComputedStyle(window.document.body);
  return [
    marginTop,
    borderTopWidth,
    paddingTop,
    height,
    paddingBottom,
    borderBottomWidth,
    marginBottom,
  ].reduce((total, value) => total + Number(value.slice(0, -2)), 0);
};

export const updateIFrameHeight = (iFrame: HTMLIFrameElement | null) => {
  if (iFrame?.contentWindow) {
    iFrame.height = '';
    iFrame.height = String(
      iFrame?.contentDocument?.body.scrollHeight ?? getHeightOfBody(iFrame.contentWindow),
    );
  }
};

export const updatingFrameHeightOnLoadOrResize = (iFrame: HTMLIFrameElement | null) => {
  if (!iFrame) {
    return () => undefined;
  }
  const offLoad = onEvent(iFrame, 'load', () => {
    updateIFrameHeight(iFrame);
  });
  const offResize = onEvent(window, 'resize', () => {
    updateIFrameHeight(iFrame);
  });
  return () => {
    offLoad();
    offResize();
  };
};
