import { FC } from 'react';
import { useViewAllTracking } from '@hooks/carousel/useViewAllTracking';
import { Link } from '../../../UI';
import styles from '../../ProductCarousel.module.scss';

export interface SeeAllLinkProps {
  href: string;
  children?: string;
  ariaLabel?: string;
  className?: string;
  classNameLinkText?: string;
  nonFocusable?: boolean;
}

export const SeeAllLink: FC<SeeAllLinkProps> = ({
  href,
  className,
  classNameLinkText,
  children,
  ariaLabel,
  nonFocusable,
}) => {
  const { onClick } = useViewAllTracking(ariaLabel);

  return nonFocusable ? (
    <span className={styles.with_underline}>{children}</span>
  ) : (
    <Link
      className={className}
      classNameLinkText={classNameLinkText}
      href={href}
      ariaLabel={ariaLabel}
      onClick={onClick}
    >
      <span className={styles.with_underline}>{children}</span>
    </Link>
  );
};
