import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { ProductTile } from '@commons/product';
import { ProductCarousel } from '@components/ProductCarousel/ProductCarousel';
import { Label } from '@components/ProductCarouselWithTiles/components/label/label';
import { useLocalization } from '@hooks/index';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { getCurrentTitle } from '@utils/getCurrentTitle';
import { ProductListTitleProvider } from '@modules/ga/context/title';
import { CHANNEL_LIST, useProductListChannel } from '@modules/ga/context/channel';
import { CriteoFormatBeacon } from '@modules/criteo';
import { DYNAMIC_FEATURES, isFeatureEnabled } from '@utils/dynamicFeatures';
import { TermsModule } from '../Modules/TermsModule/TermsModule';
import { SeeAllLink } from '../ProductCarousel/components';
import styles from './ProductCarouselWithTiles.module.scss';
import { useTrimmedProducts } from './useTrimmedProducts';

export interface ProductCarouselWithTilesProps {
  products: ProductTile[];
  title: string;
  className?: string;
  linkToAll?: string;
  linkToAllHidden?: boolean;
  linkText?: string;
  filtered?: boolean;
  filteredUrlTitle?: string;
  onProductsTrimmed?: (productsLength: number) => void;
  termsLinkText?: string;
  termsModalContent?: string;
  trimProductsFilter?: (products: ProductTile[]) => ProductTile[];
  shouldCheckMinItems?: boolean;
  productCarouselType?: string;
  classNameForLink?: string;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  hasLeftNav?: boolean;
}

enum PRODUCTS_NUMBER_PER_SLIDE {
  MOBILE = 2,
  TABLET = 4,
  DESKTOP = 6,
}

const MAX_SLIDES = 2;

export function getMaxProductsNumberPerSlide({
  isMobile,
  isTablet,
  isLaptop,
  hasLeftNav,
}: {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop?: boolean;
  hasLeftNav?: boolean;
}) {
  if (isMobile) return PRODUCTS_NUMBER_PER_SLIDE.MOBILE;
  if (isTablet || (hasLeftNav && isLaptop)) return PRODUCTS_NUMBER_PER_SLIDE.TABLET;
  return PRODUCTS_NUMBER_PER_SLIDE.DESKTOP;
}

export const ProductCarouselWithTiles = ({
  className,
  products = [],
  title,
  linkToAll = '',
  linkToAllHidden = false,
  linkText,
  filtered = false,
  filteredUrlTitle,
  onProductsTrimmed,
  termsLinkText,
  termsModalContent,
  trimProductsFilter,
  shouldCheckMinItems = true,
  productCarouselType,
  classNameForLink,
  criteoFormatBeaconView,
  hasLeftNav,
  ...props
}: ProductCarouselWithTilesProps) => {
  const router = useRouter();
  const { getChannel } = useProductListChannel();
  const { screenSize } = useHeaderContext();
  const isSmallScreen = !!screenSize?.isSmallScreen;
  const isMobile = !!screenSize?.isMobile;
  const isTablet = !!screenSize?.isTablet;
  const isLargeTablet = !!screenSize?.isLargeTablet;
  const { t } = useLocalization();
  const { getTrimmedProducts } = useTrimmedProducts();
  const isV2 = isFeatureEnabled(DYNAMIC_FEATURES.NEW_PRODUCT_TILES);
  const isV2Tablet = () => (isSmallScreen && !isLargeTablet && !isTablet) || isTablet;
  const getIsTablet = () => isTablet || isLargeTablet;
  const maxProductsNumberPerSlide = getMaxProductsNumberPerSlide({
    isMobile: isMobile,
    isTablet: isV2 ? isV2Tablet() : getIsTablet(),
    isLaptop: screenSize?.isLaptop,
    hasLeftNav,
  });
  const availableProducts = products.filter((product) => product.available);
  const showLinkToAllSlide =
    !!linkToAll && maxProductsNumberPerSlide * MAX_SLIDES < availableProducts.length;
  const showLinkToAllButton =
    !!linkToAll && !linkToAllHidden && maxProductsNumberPerSlide < availableProducts.length;
  const trimmedProducts = trimProductsFilter
    ? trimProductsFilter(products)
    : getTrimmedProducts(availableProducts, {
        showLinkToAllSlide,
        slideSize: maxProductsNumberPerSlide,
        filtered,
        shouldCheckMinItems,
      });
  const linkAriaLabel = linkText ? `${linkText} ${title}` : `${t('carousel.seeAll')}: ${title}`;
  const urlTitle = filtered && filteredUrlTitle ? filteredUrlTitle : t('carousel.seeAll');

  useEffect(() => {
    if (onProductsTrimmed) {
      onProductsTrimmed(trimmedProducts.length);
    }
  }, [onProductsTrimmed, trimmedProducts]);

  if (!trimmedProducts.length) return null;

  const showTerms = !!linkText && !!termsModalContent;

  const titleContent = (
    <>
      <Typography className={styles.title} variant="h5" component="h2">
        <Label text={title || ''} token={t('carousel.peakQuality')} />
      </Typography>
      {showLinkToAllButton && (
        <SeeAllLink
          href={linkToAll}
          className={cx(styles.link, classNameForLink)}
          ariaLabel={linkAriaLabel}
        >
          {linkText ?? urlTitle}
        </SeeAllLink>
      )}
    </>
  );

  const titleContentWithTerms = (
    <div>
      <div className={styles.title_wrapper}>{titleContent}</div>
      <TermsModule linkText={termsLinkText} modalContent={termsModalContent} />
    </div>
  );

  return (
    <ProductListTitleProvider
      title={getCurrentTitle(
        router.pathname,
        getChannel() !== CHANNEL_LIST.recBrSearch ? title : '',
      )}
    >
      <ProductCarousel
        {...props}
        className={className}
        products={trimmedProducts}
        productCarouselType={productCarouselType}
        productsPerView={maxProductsNumberPerSlide}
        linkToAll={showLinkToAllSlide ? linkToAll : ''}
        name={title}
        title={showTerms ? titleContentWithTerms : titleContent}
        criteoFormatBeaconView={criteoFormatBeaconView}
        isV2={isV2}
      />
    </ProductListTitleProvider>
  );
};
