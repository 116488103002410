import { ProductTile } from '@commons/product';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';

const MIN_PRODUCTS_FOR_MOBILE = 2;
const MIN_PRODUCTS = 4;
const MIN_FILTERED_ITEMS = 1;
const MAX_SLIDES = 2;
const MIN_SLIDES = 1;
const MIN_EXPRESS_PRODUCTS = 1;

interface UseTrimmedProductsOptions {
  slideSize: number;
  showLinkToAllSlide: boolean;
  filtered: boolean;
  shouldCheckMinItems: boolean;
}

//TODO get rid of this hook, filter products in the upper component instead (f.e. ReorderCarousel component and useTrimReorderProducts.ts hook)
export const useTrimmedProducts = () => {
  const { enabled: expressEnabled } = useExpressContext();
  const { screenSize } = useHeaderContext();
  const getTrimmedProducts = (
    products: ProductTile[],
    { slideSize, showLinkToAllSlide, filtered, shouldCheckMinItems }: UseTrimmedProductsOptions,
  ) => {
    const slidesCount = Number(products?.length) > slideSize ? MAX_SLIDES : MIN_SLIDES;
    const maxItems = slideSize * slidesCount - Number(showLinkToAllSlide);
    const minMobileProducts = filtered ? MIN_FILTERED_ITEMS : MIN_PRODUCTS_FOR_MOBILE;
    const minProducts = filtered ? MIN_FILTERED_ITEMS : MIN_PRODUCTS;
    const minNonExpressItems = !!screenSize?.isMobile ? minMobileProducts : minProducts;
    const minItems = expressEnabled ? MIN_EXPRESS_PRODUCTS : minNonExpressItems;

    if (!products || (products.length < minItems && shouldCheckMinItems)) return [];

    if (products.length > maxItems) {
      return products.slice(0, maxItems);
    }

    if (products.length < maxItems) {
      return [...products, ...new Array(maxItems - products.length).fill(null)];
    }

    return products;
  };

  return { getTrimmedProducts };
};
