import styles from '@components/ProductCarouselWithTiles/ProductCarouselWithTiles.module.scss';
import { topPick as TopPick } from '@assets/icons/system';

interface LabelProps {
  text: string;
  token: string;
}

export const Label = ({ text, token }: LabelProps) => {
  const regSeparator = new RegExp(`\\b${token}\\b`, 'gim');
  const [title, ...titles] = text.split(regSeparator);
  if (titles.length === 0) return <>{text}</>;
  return (
    <>
      {title}
      <span className={styles.top_pick}>
        <TopPick width={19} height={19} /> {token}
      </span>
      {titles.join()}
    </>
  );
};
