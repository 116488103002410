import cx from 'classnames';
import { Button } from '@components/UI/Button/Button';
import { useModal } from '@hooks/common/useModal';
import { TermsModal } from '@components/Modules/TermsModal/TermsModal';
import styles from './TermsModule.module.scss';

interface TermsLinkProps {
  linkText?: string;
  modalContent?: string;
  className?: string;
}

export const TermsModule = ({ linkText, modalContent, className }: TermsLinkProps) => {
  const shouldShowTermsModule = !!linkText && !!modalContent;
  const { isOpen, openModal, closeModal } = useModal();

  if (!shouldShowTermsModule) return null;

  return (
    <>
      <Button
        className={cx(styles.link, className)}
        variant="underline"
        onClick={() => {
          openModal();
        }}
      >
        {linkText}
      </Button>
      <TermsModal text={modalContent} open={isOpen} onClose={closeModal} />
    </>
  );
};
