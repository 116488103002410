import cx from 'classnames';
import { ReactElement, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@mui/material';
import { ProductTile as IProductTile } from '@commons/product';
import { ProductTileManager } from '@components/Tiles/ProductTile/ProductTileManager';
import { useLocalization } from '@hooks/useLocalization';
import { CriteoFormatBeacon } from '@modules/criteo';
import breakpoints from '@styles/breakpoints.module.scss';
import { ProductCarouselNavigation, SeeAllLink } from './components';
import styles from './ProductCarousel.module.scss';

export enum CAROUSEL_TYPE {
  FULLWIDTH = 'fullwidth',
  HALFWIDTH = 'halfwidth',
}

export interface ProductCarouselProps {
  productsPerView: number;
  products: Array<IProductTile | null>;
  type?: CAROUSEL_TYPE;
  linkToAll?: string;
  name?: string;
  title?: ReactElement;
  className?: string;
  freeItem?: boolean;
  hideShoppingListButton?: boolean;
  productCarouselType?: string;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  isV2?: boolean;
}

export const ProductCarousel = ({
  title,
  productsPerView,
  products,
  name,
  className,
  linkToAll,
  freeItem = false,
  hideShoppingListButton = false,
  type = CAROUSEL_TYPE.FULLWIDTH,
  productCarouselType,
  criteoFormatBeaconView,
  isV2 = false,
  ...props
}: ProductCarouselProps) => {
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [activeSlide, setActiveSlide] = useState(1);
  const { t } = useLocalization();

  const isNavigationVisible = products.length > productsPerView;
  const totalSlides = Math.ceil(products?.length / productsPerView);
  if (!products.length) return null;

  let breakpointsConfig = {
    [breakpoints.xs]: {
      slidesPerView: 2,
    },
    [breakpoints.sm]: {
      slidesPerView: type === CAROUSEL_TYPE.FULLWIDTH ? 4 : 2,
    },
    [breakpoints.lg]: {
      slidesPerView: type === CAROUSEL_TYPE.FULLWIDTH ? productsPerView : 3,
      allowTouchMove: false,
    },
  };

  if (isV2) {
    breakpointsConfig = {
      [breakpoints.xs]: {
        slidesPerView: 2,
      },
      [breakpoints.small]: {
        slidesPerView: type === CAROUSEL_TYPE.FULLWIDTH ? 4 : 2,
      },
      [breakpoints.largeTablet]: {
        slidesPerView: type === CAROUSEL_TYPE.FULLWIDTH ? productsPerView : 3,
        allowTouchMove: false,
      },
    };
  }

  return (
    <Swiper
      {...props}
      breakpoints={breakpointsConfig}
      className={cx(styles.carousel, className, { [styles.v2]: isV2 })}
      virtual={false}
      spaceBetween={24}
      maxBackfaceHiddenSlides={0}
      slidesPerView={productsPerView}
      slidesPerGroup={productsPerView}
      watchSlidesProgress
      onSlideChange={(swiper) => {
        setIsEnd(swiper.isEnd);
        setIsStart(swiper.isBeginning);
        setActiveSlide(swiper?.snapIndex + 1);
      }}
      preloadImages={false}
      role="region"
      aria-label="Carousel"
      wrapperTag="ul"
    >
      <div slot="container-start" className={styles.header}>
        {title}
        {isNavigationVisible && (
          <ProductCarouselNavigation
            isEnd={isEnd}
            isStart={isStart}
            className={styles.navigation}
            name={name || ''}
          />
        )}
        <Box aria-live="polite" className="sr_only">
          slide {activeSlide} of {totalSlides}
        </Box>
      </div>
      {products.map((product: IProductTile | null, index) =>
        product ? (
          <SwiperSlide
            tag="li"
            key={`${product.productId}_${index}`}
            data-testid={`product-carousel-slide-${product.productId}`}
            className={cx(styles.slide, {
              [styles.slide_fullwidth]: type === CAROUSEL_TYPE.FULLWIDTH,
              [styles.slide_halfwidth]: type === CAROUSEL_TYPE.HALFWIDTH,
              [styles.separator]: index > 0 && !isV2,
              [styles.v2]: isV2,
            })}
          >
            {({ isVisible }) => (
              <ProductTileManager
                product={product}
                productCarouselType={productCarouselType}
                className={styles.product}
                nonFocusable={!isVisible}
                isShowImage
                freeItem={freeItem}
                hideShoppingListButton={hideShoppingListButton}
                criteoFormatBeaconView={criteoFormatBeaconView}
                isV2={isV2}
              />
            )}
          </SwiperSlide>
        ) : (
          <SwiperSlide key={index} className={styles.slide} tag="li" />
        ),
      )}
      {linkToAll && (
        <SwiperSlide className={styles.slide} tag="li">
          {({ isVisible }) => (
            <SeeAllLink
              className={styles.last_slide}
              classNameLinkText={styles.link_text}
              href={linkToAll}
              nonFocusable={!isVisible}
              ariaLabel={`${t('carousel.seeAll')} ${name}`}
            >
              {`${t('carousel.seeAll')} ${name}`}
            </SeeAllLink>
          )}
        </SwiperSlide>
      )}
    </Swiper>
  );
};
